import React, { useEffect, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const [scroll, setScroll] = useState(false);
 useEffect(() => {
   window.addEventListener("scroll", () => {
     setScroll(window.scrollY > 100);
   });
 }, []);

  return (
    <>
      <div className="custom-navbar">
        <div className="container">
          <Navbar expand="lg">
            <NavbarBrand href="/">
              <img
                src="./images/logo.webp"
                alt="FrrightGain"
                title="FrrightGain"
              />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            {/* <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink href="/components/" title="Products">
                    Products
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/components/" title="Services">
                    Services
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/components/" title="Pricing">
                    Pricing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/components/" title="Contact Us">
                    Contact Us
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav title="Solution">
                  Solutions
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Option 1</DropdownItem>
                  <DropdownItem>Option 2</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>Reset</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
                <NavItem>
                  <NavLink className="let-start" href="#" title="Login & Sign Up">
                    <img src="./images/login-icon.svg"/> Login / Sign Up
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse> */}
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default Header;
