import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faPhone,
  faMapMarker,
  faEnvelope,
  faMapMarkerAlt,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
  };
  
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
        if (window.scrollY > 400) {
            setShowTopBtn(true);
        } else {
            setShowTopBtn(false);
        }
    });
}, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <>
      <section>
        <div className="footer-wrapper">
          <div className="container">
            <div className="inner-footer">
              <div className="footer-menu">
                <div className="row">
                  <div className="col-md-4">
                    <div className="ft-wrapper">
                      <div className="footer-logo-wrap">
                        <img
                          className="footer-img"
                          src="./images/footer-logo.webp"
                          alt="FrrightGain"
                          title="FrrightGain"
                        />
                      </div>
                      <h4 className="get-in-touch">
                        Get in touch for a <br /> detailed demo.
                      </h4>
                      <div className="orange-line-wrap">
                        <img
                          src="./images/orange-line.svg"
                          alt="Orange Divide"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="ft-wrapper">
                      <h5>Reach Us</h5>
                      <ul className="reach-us">
                        <li>
                          <span className="font-icon">
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                          </span>{" "}
                          <span className="content-text">
                          B-604 Damji Shamji Corporate Square Off A G Link Road , Next to Kanara Business Centre Pant Nagar Ghatkopar East Mumbai City Maharashtra- 400075
                          </span>
                        </li>
                        <li>
                          <span className="font-icon">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          <a href="tel:3075550133" title="Call Us">
                            <span className="content-text">(307) 555-0133</span>
                          </a>
                        </li>
                        <li>
                          <span className="font-icon">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                          <a href="mailto:service@trago.com" title="Email Us">
                            <span className="content-text">
                              service@trago.com
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="ft-wrapper">
                      <h5>Social Links</h5>
                      <div className="social-wrap-footer">
                        <ul className="social-icon">
                          <li>
                            <a href="#" title="Facebook">
                              <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Twitter">
                              <FontAwesomeIcon icon={faTwitter} />
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Linkedin">
                              <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                          </li>
                          <li>
                            <a href="#" title="Instagram">
                              <FontAwesomeIcon icon={faYoutube} />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {" "}
                {showTopBtn && (
                <button
                  title="scroll to top"
                  className="scroll-top"
                  onClick={scrollToTop}
                >
                  <FontAwesomeIcon icon={faArrowUp} />
                </button>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="copywrite-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-left left-copywrite">
                <p>{new Date().getFullYear()} © FreightGain Co. All Rights Reserved. </p>
              </div>
              <div className="col-md-6 text-right right-copywrite">
                <ul>
                  {/* <li>
                    <Link to="/" title="Disclaimer">
                      Disclaimer
                    </Link>
                  </li>

                  <li>
                    <Link to="/" title="Terms of Use">
                      Terms of Use
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/privacypolicy" title="Privacy Policy">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
